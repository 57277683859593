import React from 'react';
import {Container} from 'react-bootstrap';
import Layout from "../../components/layout";
import {StaticImage} from "gatsby-plugin-image";

class DerbyshireTimes extends React.Component {
    render() {
        return (
            <Layout>
                <h1>Derbyshire Times, August 11, 2011</h1>
                <StaticImage src="../../images/derbyshiretimes/ResizedImage600769-2461661.jpg"
                             alt="Derbyshire Times Page 1"/>
            </Layout>);
    }
}

export default DerbyshireTimes;
